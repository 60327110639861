<template>
    <div class="shop_main">
        <div class="shop_search_menu">
            <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
            <i class="el-icon-arrow-right"></i>
            <a href="javascript:void(0)" @click="$router.replace('/shop/services')">增值服务</a>
            <i class="el-icon-arrow-right"></i>
            <span class="on">供应链金融服务</span>
        </div>

        <div class="shopServicesBanner">
            <img :src="'./img/services05.png'" class="bg" />
            <div class="content">
                <div class="title">
                    <h1>供应链金融服务</h1>
                </div>

                <div class="info">
                    <p class="text">应收账款融资｜订单融资｜存货融资｜预付款融资</p>
                    <p class="text">保单贷款｜设备融资租赁｜其他融资服务</p>
                </div>
            </div>
        </div>

        <div class="shopServicesType">
            <div class="item">
                <img :src="'./img/services06.png'" class="icon" />
                <span class="text">降低企业融资成本</span>
            </div>

            <div class="item">
                <img :src="'./img/services07.png'" class="icon" />
                <span class="text">改善企业现金流</span>
            </div>

            <div class="item">
                <img :src="'./img/services08.png'" class="icon" />
                <span class="text">提升企业融资能力</span>
            </div>

            <div class="item">
                <img :src="'./img/services09.png'" class="icon" />
                <span class="text">增强企业竞争力</span>
            </div>
        </div>

        <div class="shopServicesSubBanner">
            <img :src="'./img/services10.png'" class="bg">
            <div class="content">
                <img :src="'./img/services11.png'" class="icon">
                <img :src="'./img/services12.png'" class="icon">
                <img :src="'./img/services13.png'" class="icon">
            </div>
        </div>

        <div class="shopServicesForm">
            <img :src="'./img/services14.png'" class="bg">
            <div class="shop_form">
                <el-form ref="form" :model="form" :rules="rules" label-width="142px">
                    <el-form-item label="公司名称：" prop="companyName">
                        <el-input v-model="form.companyName" placeholder="请输入公司名称"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人：" prop="userName">
                        <el-input v-model="form.userName" placeholder="请输入联系人姓名" class="inputName"></el-input>

                        <el-radio-group v-model="form.userSex">
                            <el-radio :label="1">先生</el-radio>
                            <el-radio :label="2">女士</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="联系电话：" prop="phone">
                        <el-input v-model="form.phone" placeholder="请输入联系电话，以便我们和您取得联系！"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：" prop="email">
                        <el-input v-model="form.email" placeholder="请输入您的邮箱号码，方便我们在线与您取得联系！"></el-input>
                    </el-form-item>
                    <el-form-item label="洽谈内容：" prop="dese">
                        <el-input v-model="form.dese" placeholder="请输入合作洽谈相关内容" type="textarea" :rows="2"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="text">了解更多详情，请填写以上信息，我们将会在1个工作日内与您联系。</span>
                    </el-form-item>
                    <el-form-item style="text-align: center; padding-right: 142px">
                        <el-button type="primary" :loading="submitLoading" @click="submitForm('form')">提交申请</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div class="shopServicesContact">
            <img :src="'./img/services15.png'" class="icon">
            <div class="tell">全国咨询热线：400-091-8879</div>
            <div class="item">
                <img :src="'./img/services16.png'" class="code">
                <span>微信公众号</span>
            </div>
            <div class="item">
                <img :src="'./img/services17.png'" class="code">
                <span>官方客服微信</span>
            </div>
        </div>
    </div>
</template>

<script>
  import {postCooperate} from "../../../api/shop/article";

  export default {
    name: "shopServicesFinance",
    data () {
      return {
        form: {
          resource: 1,
          userSex: 1,
          userName: '',
          companyName: '',
          phone: '',
          email: '',
          dese: '',
        },
        rules: {
          userName: [
            { required: true, message: '请输入联系人姓名', trigger: 'blur' }
          ],
          companyName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入联系电话', trigger: 'blur' },
            { pattern: '^([1]\\d{10}|([\\(（]?0[0-9]{2,3}[）\\)]?[-]?)?([2-9][0-9]{6,7})+(\\-[0-9]{1,4})?)$', message: '请输入正确的联系电话', trigger: 'blur'}
          ],
          dese: [
            { required: true, message: '请输入合作洽谈相关内容', trigger: 'blur' }
          ]
        },
        submitLoading: false
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
    },
    methods: {
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            postCooperate({
              data: this.form
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '您的资料已提交，我们会在1-3个工作日内联系您！',
                  showClose: true,
                  type: 'success',
                  customClass: 'messageCooperate'
                })
                this.$refs['form'].resetFields()
              }
            }).catch(() => {
              this.submitLoading = false
            })
          } else {
            return false;
          }
        });
      }
    },
    components: {}
  }
</script>
